import { classNames } from "src/helpers/classNames";
import React from "react";
import { formatCurrency } from "src/helpers";
import { connect } from "react-redux";

const PlanBodyContent = ({ data = null, setLists = () => {}, ...props }) => {
  return (
    <div className="">
      <div className="main_item-background relative px-8 pb-6 pt-8">
        <div>
          <div className="flex w-full items-center gap-3 space-x-2 text-4xl font-semibold">
            {data?.name || "Plan Name"}{" "}
            <div className={classNames("flex h-8 items-center justify-center gap-2 rounded border px-2 text-base", data?.status ? "border-green-500 bg-green-50 text-green-500" : "border-red-500 bg-red-50 text-red-500")}>{data?.status ? "Active" : "In-active"}</div>
          </div>
          <div className="py-1 pl-0.5 text-sm opacity-75">{data?.description || "No description provided."}</div>
        </div>
        <div className="my-3 flex items-center space-x-3">
          {data?.pricing?.rates?.map((price, index) => {
            const originalAmount = price?.amount || 0;
            const discountedAmount = originalAmount - (price?.discount_amount || 0);
            const discountPercentage = price?.discount_percentage || 0;
            return (
              <div
                key={price?._id}
                className="flex items-center space-x-2">
                {discountPercentage > 0 && <span className="text-xl text-gray-400 line-through">{formatCurrency(originalAmount, data?.currency)}</span>}
                <span className="text-xl font-medium text-gray-600">
                  {formatCurrency(discountedAmount, data?.currency)}/{price?.interval}
                </span>
                {index < data.pricing.rates.length - 1 && <span className="text-xl">|</span>}
                {discountPercentage > 0 && <span className="text-sm font-semibold text-green-600">Save {discountPercentage}%</span>}
              </div>
            );
          })}
        </div>
        <div className="space-y-2">
          {data?.free_trial?.status && (
            <div className="rounded-md border border-blue-100 bg-blue-50 px-4 py-2">
              <span className="text-sm font-medium text-blue-600">Free Trial:</span>
              <span className="ml-2 text-lg font-semibold text-blue-800">{data?.free_trial?.days} days</span>
            </div>
          )}
        </div>
        {/* <div className="my-3 py-3">
          <Tabs tabs={tabs} />
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(PlanBodyContent);
