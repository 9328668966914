import React, { useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

const ClientSetupItem = ({ item = null, statuses = null, details = null, refreshData = () => {} }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const clientSetup = async () => {
    try {
      setIsDisabled(true);
      await apiRequest("POST", `/clients/:client_id/setup`, { body: { code: item.code }, params: { client_id: details?._id } }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      refreshData();
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <div className="flex justify-between gap-4 py-4">
      <div className="flex-shrink flex-grow">
        <div className="flex items-center gap-4">
          <div className="text-lg font-semibold text-gray-600">{item.name}</div>
          <div className={classNames("rounded border px-2 py-1 text-[10px] font-semibold !leading-none", statuses[item?.status]?.class)}>{statuses[item?.status]?.text}</div>
        </div>
        <div className="text-sm text-gray-400">{item.description}</div>
      </div>
      {["pending", "failed"]?.includes(item?.status) && (
        <div className="w-30 flex flex-shrink-0 flex-col justify-between">
          <Button
            disabled={isDisabled}
            loading={isDisabled}
            version="primary"
            onClick={clientSetup}>
            Manually Launch
          </Button>
        </div>
      )}
    </div>
  );
};

export default ClientSetupItem;
