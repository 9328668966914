import { ArrowTopRightOnSquareIcon, Cog8ToothIcon, DocumentDuplicateIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Buttons/Button";
import CopyToClipboard from "react-copy-to-clipboard";
import { classNames } from "src/helpers/classNames";
import Tabs from "src/components/Shared/Tabs/Tabs";
import ClientSetup from "./Setup/ClientSetup";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import React, { useState } from "react";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import UserProfile from "src/components/Users/UserProfile";

const ClientBodyContent = ({ data = null, statuses = null, ...props }) => {
  const [statusData, setStatusData] = useState({ status: data?.status, isUpdating: true });

  return (
    <div className="">
      <div className="main_item-background relative px-8 pb-6 pt-8">
        <div className="mb-5 flex items-center space-x-4">
          <h1 className="text-4xl font-bold text-gray-900">{data?.company_name || data?.name}</h1>
          {statusData?.isUpdating ? (
            <div className="relative">
              <Preloader
                color="text-gray-400 fill-gray-700"
                size="xs"
                className="!px-0 !py-2"
                circleDimension={"6"}
              />
            </div>
          ) : (
            <div className=" flex items-center ">
              <div className={classNames("flex h-8 items-center justify-center gap-2 rounded border px-2", statuses[statusData?.status]?.class)}>{statuses[statusData?.status]?.text}</div>
            </div>
          )}
        </div>
        <div className="mb-5 flex items-center space-x-2">
          <div className="h-12 w-12 overflow-hidden rounded-full">
            <UserProfile
              size="xl"
              user={data}
              className="!bg-highlightColor !text-white"
            />
          </div>
          <div>
            <div className="text-lg font-bold text-gray-900">{data?.name}</div>
            <div className="text-sm font-semibold text-gray-600">{data?.email}</div>
          </div>
        </div>
        <div className="flex items-center gap-5">
          <div className="flex w-40 items-center gap-2 font-semibold text-gray-600">
            <GlobeAltIcon className="h-5 w-5 stroke-2" /> Domain
          </div>
          <div className="relative flex w-full max-w-md items-center gap-5">
            <input
              type={"domain"}
              value={data?.domain ? data?.domain + "/manage" : ""}
              className="h-10 w-full rounded border-0 bg-gray-200/40 px-3 py-0 pr-10 font-semibold !ring-0"
              onChange={() => {}}
            />
            <div className="absolute right-1 top-1 z-10 flex gap-x-1">
              <CopyToClipboard
                text={data?.domain ? data?.domain + "/manage" : ""}
                onCopy={() => toast.success("Copied")}>
                <Button
                  type="button"
                  version="default"
                  className="flex !h-8 !w-8 items-center justify-center rounded border border-gray-200 bg-white text-gray-500 shadow-sm">
                  <DocumentDuplicateIcon className="h-5 w-5 stroke-2" />
                </Button>
              </CopyToClipboard>
              <div className="flex !h-8 !w-8 items-center justify-center rounded border border-gray-200 bg-white text-gray-500 shadow-sm">
                <a
                  href={data?.domain ? `https://${data?.domain}/manage` : ""}
                  rel="noreferrer"
                  target="_blank"
                  type="button"
                  version="default"
                  className="flex !h-8 !w-8 items-center justify-center rounded border border-gray-200 bg-white text-gray-500 shadow-sm">
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-2" />
                </a>
              </div>
            </div>
          </div>
          <div className="relative">
            <Button
              type="button"
              version="default"
              className="flex h-10 items-center justify-center px-2 py-0 font-semibold text-gray-500 underline">
              Edit
            </Button>
          </div>
        </div>
      </div>
      <div className="px-8 pb-4">
        <Tabs
          tabs={[
            {
              name: "Setup Steps",
              icon: Cog8ToothIcon,
              component: (
                <ClientSetup
                  details={data}
                  statuses={statuses}
                  setStatusData={setStatusData}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(ClientBodyContent);
