import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { classNames } from "src/helpers/classNames";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import ProfileImage from "../ProfileImage";
import { updateUser } from "src/actions/auth";

const UserAddModal = ({ data, isOpen, onCancel = () => {}, onSuccess = () => {}, user, updateUser }) => {
  const jsonData = {
    name: "",
    email: "",
    image: "",
    password: "",
    send_welcome_email: true,
    confirm_password: "",
  };

  const [formData, setFormData] = useState(jsonData);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setFormData(data?._id ? data : jsonData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async () => {
    try {
      setIsDisabled(true);
      const url = formData?._id ? "/users/:user_id" : "/users";
      const method = formData?._id ? "PUT" : "POST";
      const { data } = await apiRequest(method, url, { body: formData, params: { user_id: formData?._id } });
      if (data?.data?._id === user?._id) updateUser(data?.data);
      onSuccess();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <>
      <Modal
        title="Admin"
        secondaryTitle={data?._id ? "Edit" : "Add"}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onSubmit}
        isLoading={isDisabled}>
        <div className="flex w-full flex-wrap gap-y-4">
          <div className="w-full min-w-[200px] px-2">
            <ProfileImage
              user={formData}
              image={formData?.image}
              setImage={(value) => setFormData((prevData) => ({ ...prevData, image: value }))}
            />
          </div>
          <div className="w-full px-2 sm:w-1/2">
            <Input
              autocomplete="off"
              name="full-name"
              label="Full name"
              inline={true}
              type="text"
              value={formData?.name}
              onChange={(e) => setFormData((prevData) => ({ ...prevData, name: e.target.value }))}
            />
          </div>
          <div className="w-full px-2 sm:w-1/2">
            <Input
              name="email"
              label="Email"
              inline={true}
              value={formData?.email}
              onChange={(e) => setFormData((prevData) => ({ ...prevData, email: e.target.value }))}
            />
          </div>
          {!data?._id && (
            <div className="w-full">
              <div className="grid max-h-[200px] gap-y-3 opacity-100 transition-all duration-150">
                <ToggleHeader
                  title="Send welcome email on setup"
                  subtitle="Disabling this will allow you to manually enter a password"
                  position="left">
                  <Toggle
                    checked={formData?.send_welcome_email}
                    onChange={(e) => setFormData((prevData) => ({ ...prevData, send_welcome_email: !prevData?.send_welcome_email }))}
                  />
                </ToggleHeader>
              </div>
              <div className={classNames("grid gap-y-3 transition-all duration-150", formData?.send_welcome_email ? "mt-2 max-h-0 overflow-hidden opacity-0" : "mt-6 max-h-[200px] opacity-100")}>
                <p className="p-0 text-sm text-gray-300">
                  Please enter a <span className="px-[2px] font-semibold text-gray-400">temporary</span> password that should be reset upon logging in.
                </p>
                <div className="flex w-full gap-x-4 gap-y-2">
                  <div className="w-full">
                    <Input
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      label="Password"
                      value={formData?.password}
                      onChange={(e) => setFormData((prevData) => ({ ...prevData, password: e.target.value }))}
                      inline={true}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      autoComplete="new-password"
                      type="password"
                      name="confirm-password"
                      label="Confirm password"
                      inline={true}
                      value={formData?.confirm_password}
                      onChange={(e) => setFormData((prevData) => ({ ...prevData, confirm_password: e.target.value }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { updateUser })(UserAddModal);
