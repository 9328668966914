import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { apiRequest } from "src/async/apiUtils";
import NavItem from "src/components/Navigation/NavItem";
import AdminNavDesktop from "src/components/RoleViews/Admin/AdminNavDesktop";
import Button from "src/components/Shared/Buttons/Button";
import Logo from "src/components/Shared/Icons/Logo";
import SeoTitle from "src/components/Shared/Text/SeoTitle";
import UserProfile from "src/components/Users/UserProfile";
import { UserContext } from "src/context/UserContext";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";

const AdminLayout = ({ user, site, children }) => {
  const [currentNavigation, setCurrentNavigation] = useState("");
  const [menus, setMenus] = useState([]);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const userNavigation = [
    {
      name: "My account",
      path: `/admins/${user?._id}`,
      navName: "Users",
      onClick: (e) => {
        e.preventDefault();
        setShowProfileModal(true);
      },
    },
    { name: "Sign out", path: "/" },
  ];

  const ref = useRef();
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setSidebarOpen(false));

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);

  const updateNavigation = (navItem) => {
    setCurrentNavigation(navItem.href);
  };

  const loadMenuData = async () => {
    try {
      const { data: resData } = await apiRequest("GET", `/menu/list`);
      setMenus(resData.data);
    } catch (error) {}
  };

  useEffect(() => {
    loadMenuData();
  }, []);

  return (
    <>
      <SeoTitle title={site.name} />
      <div className="flex h-full w-full flex-col">
        <Transition.Root
          show={sidebarOpenMobile}
          as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lgx:hidden"
            onClose={setSidebarOpenMobile}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-leftNavColor pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                      <Button
                        type="button"
                        version="default"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:border-highlightColor focus:ring-0"
                        onClick={() => setSidebarOpenMobile(false)}>
                        <span className="sr-only">Close Side Bar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </Button>
                    </div>
                  </Transition.Child>
                  <div className="bg-headerColor pl-4">
                    <Logo />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {menus.map((item) => (
                        <NavItem
                          key={item?._id}
                          item={item}
                          activeItem={currentNavigation}
                          onClick={updateNavigation}
                        />
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div
                className="w-14 flex-shrink-0"
                aria-hidden="true">
                {/* Dummy element to force leftNavColor to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className={classNames("flex w-full transition-all duration-150", sidebarOpen ? "lgx:pl-0" : "")}>
          <div className="fixed top-0 z-30 flex h-16 w-full flex-shrink-0 bg-headerColor shadow-md">
            <Logo>
              <Button
                type="button"
                version="default"
                className="px-4 text-headerTextColor focus:border-highlightColor focus:ring-0 lgx:!hidden"
                onClick={() => setSidebarOpenMobile(true)}>
                <span className="sr-only">Open left navigation</span>
                <Bars3BottomLeftIcon
                  className="h-6 w-6 text-headerTextColor"
                  aria-hidden="true"
                />
              </Button>
              <Button
                type="button"
                version="default"
                className="hidden border-0 px-4 text-gray-500 focus:border-headerTextColor focus:ring-0 lgx:!flex"
                onClick={() => setSidebarOpen(!sidebarOpen)}>
                <span className="sr-only">Open leftNavColor</span>
                <Bars3BottomLeftIcon
                  className="h-6 w-6 text-headerTextColor"
                  aria-hidden="true"
                />
              </Button>
            </Logo>
            <div className="flex w-full items-center justify-end pr-6">
              <div className="ml-4 flex items-center lg:ml-6">
                {/* Profile dropdown */}
                <Menu
                  as="div"
                  className="flex items-center">
                  <Menu.Button className="flex items-center justify-center">
                    <div className="w-22 m-0 hidden h-6 items-center text-sm font-medium text-headerTextColor sm:flex">{user?.name ? user?.name : null}</div>
                    <span className="sr-only">Open user menu</span>
                    <div className="ml-3 h-8 w-8 overflow-hidden rounded-full shadow">{<UserProfile user={user || {}} />}</div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-7 top-12 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <AdminNavDesktop userNavigation={userNavigation} />
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex h-full w-full">
          {/* Static leftNav for desktop */}
          <div className={classNames("fixed top-16 z-20 hidden h-[calc(100%-56px)] transition-all duration-150 lgx:!flex lgx:w-64 lgx:flex-col", sidebarOpen ? "left-0" : "-left-64")}>
            <nav className="flex h-full flex-col justify-between bg-leftNavColor px-2 py-4 shadow-lg">
              <div className="grid gap-y-1">
                {menus.map((item) => (
                  <NavItem
                    key={item?._id}
                    item={item}
                    activeItem={currentNavigation}
                    onClick={updateNavigation}
                  />
                ))}
              </div>
            </nav>
          </div>
          <div className={classNames("relative z-10 w-full pt-16 transition-all duration-150", user?.type === "admin" && sidebarOpen ? "lgx:pl-64" : "lgx:pl-0")}>
            <UserContext.Provider value={{ showProfileModal, setShowProfileModal }}>
              <div className="flex min-h-[calc(100vh-56px)] w-full max-w-[1600px] flex-col gap-y-4 px-2 py-4 sm:gap-y-0 md:px-6 md:py-8 xl:px-12 xl:py-10 2xl:pl-16 2xl:pt-12 3xl:pl-24 3xl:pt-20">{children}</div>
            </UserContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(AdminLayout);
