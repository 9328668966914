import MultiColumnLayout from "src/components/Shared/MultiColumnLayout/MultiColumnLayout";
import useGetListAndSelectItem from "src/hooks/useGetListAndSelectItem";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Buttons/Button";
import ClientAddModal from "../Modal/ClientAddModal";
import ClientBodyContent from "./ClientBodyContent";
import Search from "src/components/Shared/Search";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import ClientItems from "./ClientItems";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

const ClientsList = () => {
  const statuses = {
    pending: {
      text: "Pending",
      class: "border-gray-500 bg-gray-50 text-gray-500",
    },
    in_progress: {
      text: "In progress",
      class: "border-amber-500 bg-amber-50 text-amber-500",
    },
    completed: {
      text: "Completed",
      class: "border-green-500 bg-green-50 text-green-500",
    },
    failed: {
      text: "Failed",
      class: "border-red-500 bg-red-50 text-red-500",
    },
    active: {
      text: "Active",
      class: "border-green-500 bg-green-50 text-green-500",
    },
    inactive: {
      text: "In-active",
      class: "border-red-500 bg-red-50 text-red-500",
    },
  };
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { lists, meta, isLoaded, limit, offset, setOffset, keyword, setKeyword, selectedItem, setSelectedItem, handleOnPaginationChange, refreshData } = useGetListAndSelectItem({
    url: `/clients`,
    paginationData: { limit: 10, offset: 0 },
  });

  const handleOnDeleteModalOpen = () => setDeleteModalIsOpen(true);

  const handleOnDelete = async () => {
    try {
      setIsDeleting(true);
      const { data } = await apiRequest("DELETE", `/clients/:client_id`, { params: { client_id: selectedItem?._id } });
      setDeleteModalIsOpen(false);
      refreshData();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleOnSwitchToClient = async () => {
    try {
      const { data } = await apiRequest("GET", `/clients/:client_id/token/generate`, { params: { client_id: selectedItem?._id } });
      window.open(data?.data?.url, "_blank");
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const headerActions = [{ name: "Activity" }, { name: "Settings" }, { name: "LoggedIn To Client", onClick: handleOnSwitchToClient }, { name: "", type: "hr" }, { name: "Delete", onClick: handleOnDeleteModalOpen }];

  return (
    <>
      <MultiColumnLayout
        title={"Client List"}
        subTitle={`Total of ${meta?.count || 0} clients`}
        asideLoaded={isLoaded}
        aside={lists}
        paginationData={{ meta, limit, offset }}
        handleOnPaginationChange={handleOnPaginationChange}
        itemEntry={(item) => <ClientItems data={item} />}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        mainHeaderContent={
          <>
            <div className="w-96">
              <Search
                keyword={keyword}
                setKeyword={(val) => {
                  setOffset(0);
                  setKeyword(val);
                }}
                placeholder={"Search"}
                inline={true}
              />
            </div>
            <div className="relative">
              <Button
                version="secondary"
                onClick={() => setAddModalIsOpen(true)}>
                <UserPlusIcon className="h-5 w-5" />
                Add client
              </Button>
            </div>
          </>
        }
        bodyHeaderActions={headerActions}
        bodyContent={
          <ClientBodyContent
            data={selectedItem}
            statuses={statuses}
          />
        }
      />
      <ClientAddModal
        isOpen={addModalIsOpen}
        onCancel={() => setAddModalIsOpen(false)}
        onSuccess={() => {
          setAddModalIsOpen(false);
          refreshData();
          setOffset(0);
        }}
      />
      <Modal
        title="Client"
        secondaryTitle="Delete"
        isOpen={deleteModalIsOpen}
        onCancel={() => setDeleteModalIsOpen(false)}
        isLoading={isDeleting}
        onSuccess={handleOnDelete}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
          <p className="text-lg">
            Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this client?
          </p>
          <div className="text-gray-400">Once you delete this client it's gone for good.</div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(ClientsList);
