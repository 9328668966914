import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";

const ClientAddModal = ({ isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  const formJson = {
    name: "",
    email: "",
    password: "",
    company_name: "",
    domain: "",
  };

  const [formData, setFormData] = useState(formJson);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onHandleChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { data } = await apiRequest("POST", `/clients`, { body: formData });
      toast.success(data.message);
      onSuccess();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setFormData(formJson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Modal
        title="Client"
        secondaryTitle={"Add"}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onHandleSubmit}
        isLoading={isSubmitting}>
        <div className="flex w-full flex-wrap gap-y-4">
          <div className="relative w-full">
            <Input
              inline={true}
              label={"Name"}
              wrapperClass={"bg-white"}
              placeholder={"John Doe"}
              value={formData?.name}
              onChange={(e) => onHandleChange("name", e.target.value)}
            />
          </div>
          <div className="relative w-full">
            <Input
              inline={true}
              label={"Work email"}
              wrapperClass={"bg-white"}
              placeholder={"youlooknicetoday@company.com"}
              value={formData?.email}
              onChange={(e) => onHandleChange("email", e.target.value)}
            />
          </div>
          <div className="relative w-full">
            <Input
              inline={true}
              type="password"
              name={"password"}
              label={"Password"}
              wrapperClass={"bg-white"}
              placeholder={"JohnDoe@321"}
              value={formData?.password}
              onChange={(e) => onHandleChange("password", e.target.value)}
            />
          </div>
          <div className="relative w-full">
            <Input
              inline={true}
              label={"Company"}
              wrapperClass={"bg-white"}
              placeholder={"Google Inc."}
              value={formData?.company_name}
              onChange={(e) => onHandleChange("company_name", e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(ClientAddModal);
